// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/img/pctop_bg1050.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".left-area[data-v-b92fc316]{background:#0054ac;min-height:760px}.text-free[data-v-b92fc316]{background:linear-gradient(180deg,transparent 0,transparent 60%,#ffb300 0);background:linear-gradient(180deg,transparent 0 60%,#ffb300 60%);border:#ffb300}.footer[data-v-b92fc316]{bottom:2rem;position:absolute;width:100%}.right-area[data-v-b92fc316]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;min-height:1080px;z-index:1}.supports[data-v-b92fc316]{background:hsla(0,0%,100%,.95)}@media (min-width:1024px){.left-area[data-v-b92fc316],.right-area[data-v-b92fc316]{min-height:880px}.right-area[data-v-b92fc316]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover}.what-can-contents[data-v-b92fc316]{background:linear-gradient(90deg,transparent 20px,rgba(0,0,0,.4) 90px) 0 0}}@media (min-width:640px){.login-container[data-v-b92fc316]{width:34rem}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
